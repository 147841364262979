.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    background:url(./Images/3235319.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 2rem;
    padding-left: 0;
}
.container .head{
    margin-left: 4rem;
    margin-bottom: 2rem;
}
.container .head h1{
    font-weight: bold;
    color: white;
    font-family: 'Lustria', serif;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}
.container .head p{
    color: white;
}
.card{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items:center;
    gap: 2rem;
    margin-bottom: 2rem;
}
.box {
    display: flex;
    flex-direction: row;
    align-items:center;
    gap: 1rem;
}
.box .image{
    border-radius: 50%;
    background-color: white;
    position: relative;
    padding: 2.5rem;
}
.box img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 3rem;
}
.box p{
    width: 9rem;
    color:white;
    font-weight: bold;
}