.container{
    width: 100%;
    height: 13rem;
    background:linear-gradient(rgba(0, 0, 0, 0.277),rgba(0, 0, 0, 0.281)) ,url(./Image/india-woods-hill-station-fog-coffee.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.left{
    width: 60%;
    text-align: left;
    color: white;
}
.left h2{
    letter-spacing:5px;
}
.left h1{
    letter-spacing: 3px;
}
.right button{
 background: none;
 padding: 1rem 3rem;
 border: 1px solid white;
}
.right button .btnlnk{
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
}
.right button:hover{
    background: white;
}
.right button:hover .btnlnk{
    color: #3083be;
}

@media screen and (max-width:768px) {
    .container{
        width: 100%;
        height: 13rem;
        background:linear-gradient(rgba(0, 0, 0, 0.277),rgba(0, 0, 0, 0.281)) ,url(./Image/india-woods-hill-station-fog-coffee.jpg);
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .left{
        width: 100%;
        text-align: center;
        color: white;
    }
    .right button{
        background: none;
        padding: .6rem 1rem;
        border: 1px solid white;
       }
}