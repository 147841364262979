footer{
    position: relative;
    width:100%;
    background:linear-gradient(#3083be,#1271b0);
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;
}
footer .Foot {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 1rem;
}
footer .Foot .col1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}
footer .Foot .col1 .icons img{
    width: 1.5rem;
    margin: .7rem;
}
footer .Foot .col1 img{
    width: 6rem;
}
footer .Foot .col1 p{
    width: 18rem;
    font-size: .9rem;
    color: white;
    margin-top: 1rem;
}
footer .Foot .col2 h2 {
 color: white;
 border-bottom: 2px solid orange;
 padding-bottom: .4rem;
 margin-bottom: 2rem;
 width: 9rem;
}
footer .Foot .col2 p{
    color: white;
    width: 15rem;
    margin-bottom: 1rem;
}

footer .copy{
    color: white;
    text-align: center;
    
    padding: 1rem;
    padding-bottom: 0;
}
.heart img {
     width: 1rem;
}
footer .wave{
    position: absolute;
    background:url(./Images//svg.png);
    left: 0;
    top: -120px;
    width: 100%;
    height: 120px;
    background-size: contain;
    background-position: 1000px 120px;
    
}
footer .wave#wave1{
    opacity: 1;
    animation: animate 4s linear  alternate-reverse infinite;
    top: -120px;
}
footer .wave#wave2{
    z-index: 97;
    opacity: .7;
    animation: animate1 8s linear alternate-reverse infinite;
    top: -130px;
}
footer .wave#wave3{
    z-index: 97;
    opacity: .3;
    animation: animate2 6s linear alternate-reverse infinite;
    top: -140px;
}
@keyframes animate{
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 100%;
    }
}
@keyframes animate1{
    0%{
        background-position: 100%;
    }
    100%{
        background-position: 0%;
    }
}
@keyframes animate2{
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 100%;
    }
}
.lnkbtn{
    text-decoration: none;
    color: white;
}

@media screen and (max-width:768px) {
    footer .wave{
        position: absolute;
        background:url(./Images//svg.png);
        left: 0;
        top: -40px;
        width: 100%;
        height: 40px;
        background-size: contain;
        background-position: 1000px 20px;
        
    }
    footer .wave#wave1{
        opacity: 1;
        animation: animate 4s linear  alternate-reverse infinite;
        top: -40px;
    }
    footer .wave#wave2{
        z-index: 97;
        opacity: .7;
        animation: animate1 8s linear alternate-reverse infinite;
        top: -50px;
    }
    footer .wave#wave3{
        z-index: 97;
        opacity: .3;
        animation: animate2 6s linear alternate-reverse infinite;
        top: -60px;
    }
    @keyframes animate{
        0%{
            background-position: 0%;
        }
        100%{
            background-position: 100%;
        }
    }
    @keyframes animate1{
        0%{
            background-position: 100%;
        }
        100%{
            background-position: 0%;
        }
    }
    @keyframes animate2{
        0%{
            background-position: 0%;
        }
        100%{
            background-position: 100%;
        }
    }
    
    footer .Foot {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        margin-bottom: 1rem;
    }
    footer .Foot .col2 h2 {
        color: white;
        border-bottom: 2px solid orange;
        padding-bottom: .4rem;
        margin-bottom: 2rem;
        width: 100%;
       }
}


 
 




