.container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 4rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 2rem;
}

.card1{
    width: 15rem;
    height: 18rem;
   background: linear-gradient(rgba(0, 0, 0, 0.509),rgba(0, 0, 0, 0.509)),url(./Images/Dubai1.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1rem;
   border-radius: .5rem;
   margin: 1rem;
}
.card2{
    width: 15rem;
    height: 18rem;
   background: linear-gradient(rgba(0, 0, 0, 0.509),rgba(0, 0, 0, 0.509)),url(./Images/India-Honeymoon-SOTC-Special.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1rem;
   border-radius: .5rem;
   margin: 1rem;
}
.card3{
    width: 15rem;
    height: 18rem;
   background: linear-gradient(rgba(0, 0, 0, 0.509),rgba(0, 0, 0, 0.509)),url(./Images/Egypt.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1rem;
   border-radius: .5rem;
   margin: 1rem;
}
.card4{
    width: 15rem;
    height: 18rem;
   background: linear-gradient(rgba(0, 0, 0, 0.509),rgba(0, 0, 0, 0.509)),url(./Images/Spiritual-Tour1.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1rem;
   border-radius: .5rem;
   margin: 1rem;
}
.container h2{
    font-family: 'Lustria', serif;
    color: white;
    font-size: 1.7rem;
    font-weight: 500;
}
.container h3{
    font-family: 'Lustria', serif;
    color: white;
    font-weight: 400;
}
.container p{
    font-family: 'Lustria', serif;
    color: white;
    font-size: 1.3rem;
}
.container button{
    border: none;
    border-radius: 2rem;
    background: #e16b0b;
    margin-top: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    margin-top: 14rem;
    color: white;
    font-weight: 800;
    letter-spacing: 1px;
    cursor: pointer;
}
.btnlnk{
    text-decoration: none;
    color: white;
}
@media screen and (max-width:768px) {
    .container button{
        border: none;
        border-radius: 2rem;
        background: #e16b0b;
        margin-top: 1rem;
        padding: .5rem 1rem;
        position: absolute;
        margin-top: 14rem;
        color: white;
        font-weight: 800;
        letter-spacing: 1px;
        cursor: pointer;
        width: 10rem;
    }
}


