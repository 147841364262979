.container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container img{
    width: 100%;
    height: 70vh;
}

@media screen and (max-width:768px) {
    .container img{
        width: 100%;
        height: 20vh;
    }
}