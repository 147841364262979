.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .3);
    position: sticky;
    left: 0;
    top: 0;
    z-index: 999;
}
.logo img{
    width: 6rem;
    margin-left: 4rem;
}
.navLink ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-right: 4rem;
}
.navLink ul li{
    margin-right: 5rem;
    font-size: 1.1rem;
    font-family: 'Lustria', serif;
    font-weight: bold;
}
.lnks{
    text-decoration: none;
    color: #3083be;
    transition: .3s;
}
.lnks:hover{
    color: #f2862c;
}
.mobile{
    display: none;
}
@media screen and (max-width:960px) {
    .navLink ul{
        list-style: none;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: left;
        position: fixed;
        background: #f2862c;
        width: 100%;
        height: 50%;
        top: -110%;
        left: 0%;
        transition: all .5s ease;
    }
    .logo img{
        width:3rem;
        margin-left: 1rem;
    }
    .navLink2 ul{
        list-style: none;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: left;
        position: fixed;
        background: #f2862c;
        width: 100%;
        height: 50%;
        top: 0%;
        left: 0%;
        transition: all .5s ease;
    }
    .navLink2 ul li{
        margin-right: 5rem;
        font-size: 1.1rem;
        font-family: 'Lustria', serif;
        font-weight: bold;
        padding: 1rem;
    }
    .lnks{
        text-decoration: none;
        color:white;
        transition: .3s;
    }
    .mobile{
      display: block;
      position: absolute;
      right: 2rem;
      z-index:1000;

    }
}