.heading{
    width: 100%;
    background:linear-gradient(rgba(0, 0, 0, 0.497),rgba(0, 0, 0, 0.497)), url(../ABOUT/About1/Images//bg.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.heading h1{
    font-size: 2.5rem;
    font-weight: 800;
    padding: 3.5rem;
    color: white;
}