.container{
    display: flex;
    flex-direction: column;
}
.container h1{
    font-family: 'Lustria', serif;
    font-weight:bold;
    color: #f2862c;
    margin-bottom: 1rem;
}
.container h1 span{
    color:#3083be;
}
.container p{
    font-family: 'Lustria', serif;
}
.wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: top;
    width: 100%;
    padding: 4rem;
    padding-bottom: 2rem;
}
.icon p{
    width: 100%;
}
.icon img{
    width: 2rem;
    cursor: pointer;
}