.container{
    background:linear-gradient(rgba(0, 0, 0, 0.302),rgba(0, 0, 0, 0.416)), url(./Images/25912.jpg);
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 2rem;
}
.container .r1{
    color: white;
    font-size: 2rem;
    letter-spacing: 1rem;
}
.container .r2{
    color: white;
    font-size: 3rem;
    letter-spacing: 3px;
}

@media screen and (max-width:768px) {
    .container{
        background:linear-gradient(rgba(0, 0, 0, 0.302),rgba(0, 0, 0, 0.416)), url(./Images/25912.jpg);
        width: 100%;
        height:15rem;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        padding: .4rem;
    }
    .container .r1{
        color: white;
        font-size: 1.2rem;
        letter-spacing: .5rem;
    }
    .container .r2{
        color: white;
        font-size: 1.6rem;
        letter-spacing: 3px;
    }
}