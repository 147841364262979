.heading{
    color:coral;
    margin: 3rem;
}
.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 3rem;
}
.card{
    background: white;
    width: 21rem;
    height: 24rem;
    border-radius: 1rem;
    margin: 1rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0,.2);
}
.card img{
    width:21rem;
    height: 14rem;
    border-radius: 1rem;
}
.card .content{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.card .content h2{
    color: cornflowerblue;
}
.enquiry{
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    color:coral;
    align-items: center;
    margin-top: 1rem;
    text-align: center;
}