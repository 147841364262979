.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}
.container .left img{
    width: 30rem;
    
}
.right{
    width: 50%;
}
.right h1{
    color: #f2862c;
    margin-bottom: 1rem;

}
@media screen and (max-width:768px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        width: 100%;
    }
    .container .left img{
        width: 95%;
        
    }
    .right{
        width: 98%;
    }
}