.container{
    display: flex;
    flex-direction: row;
    align-items:top;
   justify-content: center;
   text-align: center;
   width: 100%;
   padding: 3rem;
   background: url(./Images/4964089.jpg);
   background-position: center;
   background-size: cover;
}
.container .left{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    gap: 1rem;
}
.card{
    overflow: hidden;
}
.left .card p{
  border: 1px solid black;
  padding: 1rem;
}
.left .card h4{
 text-align: center;
 margin-top: 1rem;
 margin-bottom: 0rem;
}
.over{
    overflow: hidden;
    margin-top: 2rem;
}
.container .right{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}
.orange{
    color: #f2862c;
    font-weight: bold;
}
.blue{
    color:#3083be;
}
.box{
    display: flex;
    flex-direction: row;
    gap: 4rem;

}

.r1{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}
.r1 h1{
    color:#3083be;
}
.r2 h1{
    color:#3083be;
}
.r2{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}
@media screen and (max-width:768px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items:top;
       justify-content: center;
       text-align: center;
       width: 100%;
       padding: 3rem;
       background: url(./Images/4964089.jpg);
       background-position: center;
       background-size: cover;
    }
}