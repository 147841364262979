.line{
    font-family: 'Lustria', serif;
    font-weight:bold;
    color: #f2862c;
    margin-left: 4rem;
}
.line span{
    color:#3083be; 
}

.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
   
}
.card{
    width: 22rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.card img{
    width: 5rem;
    margin: 1rem;
}
.card h2{
    width: 20rem;
    color: #3083be;
}
.card p{
    width: 15rem;
}