.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 4rem;
}

.container .left h1 {
margin-bottom: 1rem;
}
.container .left h1 .blue{
    color: #3083be;
}
.container .row{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 1rem;
}
.row img {
    width: 2rem;
    margin-right: 2rem;
}
.row p{
    width: 20rem;
}
.container .right h1 {
    margin-bottom: 1rem;
    }
.container .right h1 .blue{
        color: #3083be;
    }
.card{
    display: flex;
    flex-direction: column;
}
.card .r1 input {
    margin: .5rem;
    padding: .7rem 2rem ;
    width: 14rem;
}
.card select,textarea,button {
    padding: .7rem 2rem ;
    margin: .5rem;
}
.card button{
    background: #045b9a;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
}
.map{
    display: flex;
    justify-content: center;
    align-items: center;
}
.map iframe{
    margin: 1rem;
    border: none;
    width: 70%;
    height: 30rem;
}

@media screen and (max-width:768px) {
    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 2rem;
        padding: 1rem;
    }
    .container .left{
        margin-bottom: 2rem;
    }
    .card .r1 input {
        margin: .5rem;
        padding: .7rem 2rem ;
        width: 95%;
    }
    .card select,textarea,button {
        padding: .7rem 2rem ;
        margin: .5rem;
        width: 95%;
    }
    .row img {
        width: 2rem;
        margin-right:1rem;
    }
    .row p{
        width: 15rem;
    }
}
