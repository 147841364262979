.container{
    display: flex;
    flex-direction: column;
    padding: 4rem;
    padding-bottom: 2rem;
}
.container h1{
    font-family: 'Lustria', serif;
    font-weight:bold;
    color: #f2862c;
    margin-bottom: 1rem;
}
.container h1 span{
    color:#3083be;
}
.container p{
    font-family: 'Lustria', serif;
}
@media screen and (max-width:768px) {
   
}