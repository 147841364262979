.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    background-color: #0f6cae;
    justify-content: center;
    align-items: center;
    padding: .3rem;
}
.container p{
    color: white;
    margin-right: 3rem;
}
.container a{
    color: white;
    text-decoration: none;
}
.icons{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
.icons .icon img{
    width: 1.3rem;
    margin-right: 1rem;
}
@media screen and (max-width:768px) {
    .container p{
        color: white;
        margin-right: 1rem;
        font-size: .3rem;
    }
    .icons .icon img{
        width: .4rem;
        margin-right: .5rem;
    }
}