.container{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 3rem;
}
.container .left {
  text-align: left;
  width: 50%;
  margin: 1rem;
}
.left h1{
    color: #3083be;
    margin-bottom: 1rem;
}
.container .right img{
    width: 32rem;
    border-top-right-radius: 10rem 10rem;
    border-bottom-left-radius: 10rem 10rem;
} 

@media screen and (max-width:768px) {
    .container{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem;
        margin-bottom: 2rem;
    }
    .container .left {
        text-align: left;
        width: 100%;
        margin: 1rem;
      }
      .container .right img{
        width: 100%;
        border-top-right-radius: 7rem 7rem;
        border-bottom-left-radius: 7rem 7rem;
    }
}