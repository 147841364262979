.heading{
    width: 100%;
    background:linear-gradient(rgba(0, 0, 0, 0.497),rgba(0, 0, 0, 0.497)), url(./Images/bg.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.heading h1{
    font-size: 2.5rem;
    font-weight: 800;
    padding: 3.5rem;
    color: white;
}
.container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    gap: 2rem;
    padding: 4rem;
    margin-top: 2rem;
}
.left{
    width: 60%;
}
.left h1{
    color: #f2862c;
    margin-bottom: 1rem;
}
.right img{
    width: 25rem;
    height: 20rem;
    border-top-right-radius: 10rem 8rem;
}
@media screen and (max-width:768px) {
    .container{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        padding: 1rem;
        margin-top: 2rem;
    }
    .left{
        width: 95%;
    }
    .right img{
        width: 20rem;
        height: 15rem;
        border-top-right-radius: 10rem 8rem;
    }
}