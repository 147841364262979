.container{
    width: 100%;
    
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 3rem 4rem;
}
.container .mission{
    width: 100%;
    padding: 2rem;
    border: 1px solid #ff7403;
}
.container .mission h1{
  color:#3083be;
  margin-bottom: 1rem;
}
.container .vision h1{
    color:#3083be;
    margin-bottom: 1rem;
  }
.container .vision{
    width: 100%;
    padding: 2rem;
    border: 1px solid #ff7403; 
}

@media screen and (max-width:768px) {
  .container{
    width: 100%;
    
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
}
.container .mission{
  width: 100%;
  padding: .5rem;
  border: 1px solid #ff7403;
}

.container .vision{
  width: 100%;
  padding: .5rem;
  border: 1px solid #ff7403; 
}
.container .mission h1{
  color:#3083be;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.container .vision h1{
    color:#3083be;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  .container .vision p{
    font-size: .7rem;
  }
  .container .vision li{
    font-size: .7rem;
  }
  .container .mission p{
    font-size: .7rem;
  }


}